import axios from 'axios'
import Settings from "@/config/env"

class AuthService {
    login(user) {
        return axios
            .post(Settings.API_URL + 'auth', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.data.token) {
                    response.data.data.avatar = Settings.SITE_URL + response.data.data.avatar
                    this.syncUser(response.data)
                }

                return response.data
            })
    }

    logout() {
        localStorage.removeItem('user')
    }

    register(user) {
        return axios.post(Settings.API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password
        })
    }

    syncUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
}

export default new AuthService()