import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import ru from 'vuetify/es5/locale/ru'

//import colors from 'vuetify/lib/util/colors'
//import 'vuetify/src/styles/'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { ru },
        current: 'ru',
    },
    /*theme: {
    primary: '#4ad219',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    }*/
})
