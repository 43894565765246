<template>
  <div class="fill-height" style="overflow: hidden; margin-left: 30px; padding-left: 15px;margin-right: 30px;padding-right: 15px;" >
    <v-row v-if="visibleTitle">
      <div id="title" v-if="visibleTitle">
        <img id="logo" :src="pathToLogo">
        <img id="sponsor" :src="pathToSponsor" v-if="pathToSponsor != ''">
        <h1>{{ title }}</h1>
      </div>
      </v-row>
    <v-row class="fill-height" v-resize="onResize" ref="resizableDiv">
      <v-carousel
        v-model="model"
        hide-delimiters
        hide-delimiter-background
        :show-arrows="false"
        show-arrows-on-hover
        class="fill-height"
        :height="heightCar"
    >
      <v-carousel-item
          @contextmenu="show"
          class="fill-height"
      >
        <v-sheet
            color="transparent"
            height="100%"
            tile
        >
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <v-col class="fill-height">
              <div id="tourntable" class="box">
                <h2>Положение команд</h2>
                <div v-for="(division, i) in divisions" :key="i">
                  <h3>{{ division.name }}</h3>
                  <v-data-table
                      :headers="divisionsHeaders"
                      :items="division.table"
                      :items-per-page="-1"
                      disable-filtering
                      disable-sort
                      disable-pagination
                      height="45*divison.length"
                      hide-default-footer
                      hide-default-header
                      fixed-header
                      class="theme--light"
                      style="background: transparent !important; color: rgb(36,64,130) !important; font-family: Geometria;"
                      dense
                  >
                    <template slot="no-data">
                      <v-alert :value="true" color="white" style="background: transparent !important; color: rgb(36,64,130) !important; ">
                        Нет команд
                      </v-alert>
                    </template>
                    <template v-slot:header="{ props: { headers } }">
                      <thead>
                      <tr>
                        <th v-for="(item,i) in headers" :key="i">
                          {{ item.text }}
                        </th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                      <tr
                          v-for="(item,i) in items"
                          :key="i"
                      >
                        <td v-if="divisions.length > 3" style="text-align: center !important;width:5%;height:24px !important;">{{ i + 1 }}</td>
                        <td v-else style="text-align: center !important;width:5%;">{{ i + 1 }}</td>

                        <td v-if="divisions.length > 3" style="text-align: left !important;height:24px !important;">{{ item.name }}</td>
                        <td v-else style="text-align: left !important;">{{ item.name }}</td>

                        <td v-if="divisions.length > 3" style="text-align: center !important;width:5%;height:24px !important;">{{ item.wins + item.eq + item.losses }}</td>
                        <td v-else style="text-align: center !important;width:5%;">{{ item.wins + item.eq + item.losses }}</td>

                        <td v-if="divisions.length > 3"  style="text-align: center !important;width:5%;height:24px !important;">{{ item.wins }}</td>
                        <td v-else style="text-align: center !important;width:5%;">{{ item.wins }}</td>

                        <td v-if="divisions.length > 3" style="text-align: center !important;width:5%;height:24px !important;">{{ item.eq }}</td>
                        <td v-else style="text-align: center !important;width:5%;">{{ item.eq }}</td>

                        <td v-if="divisions.length > 3" style="text-align: center !important;width:5%;height:24px !important;">{{ item.losses }}</td>
                        <td v-else style="text-align: center !important;width:5%;">{{ item.losses }}</td>

                        <td v-if="divisions.length > 3" style="text-align: center !important;width:15%;height:24px !important;">{{ item.goals + ' - ' + item.missed }}</td>
                        <td v-else style="text-align: center !important;width:15%;">{{ item.goals + ' - ' + item.missed }}</td>

                        <td v-if="divisions.length > 3" style="text-align: center !important;font-weight: bold;width:5%;height:24px !important;">{{ item.score }}</td>
                        <td v-else style="text-align: center !important;font-weight: bold;width:5%;">{{ item.score }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-col>
            <v-col class="fill-height">
              <div id="tournmatches" class="box">
                <h2>Расписание матчей</h2>
                <v-data-table
                    :headers="matchesHeaders"
                    :items="matches"
                    :items-per-page="-1"
                    disable-filtering
                    disable-sort
                    disable-pagination
                    :height="height"
                    hide-default-footer
                    hide-default-header
                    fixed-header
                    class="theme--light"
                    style="background: transparent !important; color: rgb(36,64,130) !important; font-family: Geometria;"
                    dense
                    id="matchTable"
                >
                  <template slot="no-data">
                    <v-alert :value="true" color="white" style="background: transparent !important; color: rgb(36,64,130) !important; ">
                      Нет матчей
                    </v-alert>
                  </template>
                  <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                      <th v-for="(item,i) in headers" :key="i">
                        {{ item.text }}
                      </th>
                    </tr>
                    </thead>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                    <tr
                        v-for="(item,i) in items"
                        :key="i"
                    >
                      <td style="text-align: center !important;">{{ item.field }}</td>
                      <td style="text-align: center !important;">{{ stage(item.stage, item.tour) }}</td>
                      <td style="text-align: center !important;">{{ format(new Date(item.date).getHours(),new Date(item.date).getMinutes())  }}</td>
                      <td style="text-align: right !important;">{{ item.home.name.toUpperCase() }}</td>
                      <td v-if="item.status == 0" style="text-align: center !important;">-</td>
                      <td v-else style="text-align: center !important;">{{ item.score1 + ':' + item.score2 }}</td>
                      <td style="text-align: left !important;">{{ item.guest.name.toUpperCase() }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
          v-if="pageBestPlayer"
          @contextmenu="show"
      >
        <v-sheet
            color="transparent"
            height="100%"
            tile
        >
          <v-row
              class="fill-height"
              align="center"
              justify="center"
              style="margin: 0px !important;"
          >
            <v-col class="fill-height">
              <div id="bests" class="box">
                <h2>Лучшие игроки</h2>
                <v-row
                    class="fill-height"

                >
                  <v-col class="playercard">
                    <player-card
                        title="Бомбардиры"
                        :desc="1"
                        :list="forwards"
                    ></player-card>
                  </v-col>
                  <v-col class="playercard">
                    <player-card
                        title="Ассистенты"
                        :desc="2"
                        :list="assistants"
                    ></player-card>
                  </v-col>
                  <v-col class="playercard">
                    <player-card
                      title="Гол + пас"
                      :desc="3"
                      :list="goalpluspass"
                    ></player-card>
                  </v-col>
                  <v-col class="playercard">
                    <player-card
                        title="Защитники"
                        :desc="4"
                        :list="defenders"
                    ></player-card>
                  </v-col>
                  <v-col class="playercard">
                    <player-card
                        title="Вратари"
                        :desc="5"
                        :list="goalkipers"
                    ></player-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
            v-if="pageCards"
            @contextmenu="show"
        >
          <v-sheet
              color="transparent"
              height="100%"
              tile
          >
            <v-row
                class="fill-height"
                align="center"
                justify="center"
            >
              <v-col class="fill-height">
                <div id="cards" class="box">
                  <h2>Карточки</h2>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
    </v-carousel>
    </v-row>
    <v-menu
        v-model="showMenu"
        :position-x="xMenu"
        :position-y="yMenu"
        absolute
        offset-y
        link
    >
      <v-list>
        <v-list-item @click="dialog = true">
          <v-list-item-icon><v-icon>mdi-tools</v-icon></v-list-item-icon>
          <v-list-item-title>Настройки</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="690"
        min-width="300"
    >
      <v-card>
        <v-card-title class="text-h5">
          Настройки
        </v-card-title>
        <v-col>
          <v-checkbox
              v-model="showTournament"
              label="Показать название турнира"
          ></v-checkbox>
          <v-text-field
              label="Наименование соревнования"
              v-model="tournament"
              :disabled="!showTournament"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-select
                  :items="sports"
                  label="Выберите вид спорта"
                  item-text="name"
                  item-value="id"
                  v-model="selectedSport"
                  dense
                  solo
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                  :items="tournaments"
                  label="Выберите соревнование"
                  item-text="name"
                  item-value="id"
                  v-model="selectedTournament"
                  return-object
                  dense
                  solo
              ></v-select>
            </v-col>
          </v-row>

          <v-checkbox
              v-model="showAllMatches"
              label="Показать все матчи"
          ></v-checkbox>
          <v-slider
              v-model="carouselTimeout"
              label="Время перехода, сек"
              thumb-color="primary"
              thumb-label="always"
              min="5"
              step="5"
              max="240"
          ></v-slider>
          <v-slider
              v-model="updateTimeout"
              label="Время обновления, сек"
              thumb-color="primary"
              thumb-label="always"
              min="30"
              step="10"
              max="300"
          ></v-slider>
          <v-checkbox
              v-model="showBestPlayer"
              label="Показать лучших игроков"
          ></v-checkbox>
          <v-checkbox
              v-model="showCards"
              label="Показать карточки"
          ></v-checkbox>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              :disabled="!selectedTournament"
              @click="accept"
          >
            Применить
          </v-btn>
          <v-btn
              color="blue"
              text
              @click="dialog = false"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        top
        right
        :value ="error"
    >
      {{ error }}

      <template>
        <v-btn
            color="blue"
            text
            @click="error = null"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import PlayerCard from "@/components/PlayerCard"
import CligaService from "@/services/cliga.service"

export default {
  name: 'Welcome',
  data: () => ({
    model: 0,
    showMenu: false,
    xMenu: 0,
    yMenu: 0,
    dialog: true,
    error: null,
    height: 300,
    heightCar: 400,
    timer: null,
    timerUpdate: null,
    carouselTimeout: 30,
    updateTimeout: 60,

    tournament: "",
    showTournament: true,
    tournaments: [],
    selectedTournament: null,
    sports: [{id: 0, name: "Футбол"}, {id: 1, name: "Волейбол"}],
    selectedSport: 0,

    matches: [],
    matchesHeaders: [
      {
        text: 'Пл.',
        align: 'center',
        value: 'field',
      },
      {
        text: 'Этап',
        align: 'center',
        value: 'stage',
      },
      { text: 'Время', align: 'center', value: 'date' },
      { text: 'Хозяева', align: 'right', value: 'home.name' },
      {
        text: 'Счёт',
        align: 'center',
        value: 'scored',
      },
      { text: 'Гости', align: 'left', value: 'guest.name' },
    ],
    showAllMatches: false,
    showBestPlayer: true,
    showCards: false,

    divisions: [],
    divisionsHeaders: [
      {
        text: '№',
        align: 'center',
        value: 'num',
      },
      {
        text: 'Команда',
        align: 'left',
        value: 'name',
      },
      { text: 'И', align: 'center', value: 'games' },
      { text: 'В', align: 'center', value: 'wins' },
      { text: 'Н', align: 'center', value: 'eq' },
      { text: 'П', align: 'center', value: 'losses' },
      { text: 'Мячи', align: 'center', value: 'balls' },
      { text: 'Очки', align: 'center', value: 'score' },
    ],

    forwards: [],
    assistants: [],
    goalpluspass: [],
    defenders: [],
    goalkipers: [],

    currentTournamentId: 0,
    title: "",
    pathToLogo: "http://www.cliga.ru/image/logo_cl5.png",
    pathToSponsor: "",
    visibleTitle: true,
    allMatches: true,
    pageBestPlayer: true,
    pageCards: true,
  }),
  created () {
   // document.getElementById('matchTable').addEventListener('scroll', this.onScroll);
    this.loadTournament()
  },
  methods: {
    show (e) {
      e.preventDefault()
      this.showMenu = false
      this.xMenu = e.clientX
      this.yMenu = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    loadTournament() {
      this.error = null
      CligaService.getTournaments(this.selectedSport)
          .then((response) => {
            this.tournaments = []
            for (let i=0; i<response.data.championats.length; i++) {
              let data = response.data.championats[i]
             // if (new Date(data.end_date) >= new Date()) continue
              this.tournaments.push({'id': data.championat_id, 'name': data.league.name + ' ' + data.name, 'logo': data.logo, 'sponsor': data.logo_sponsor})
            }
            for (let i=0; i<response.data.cups.length; i++) {
              let data = response.data.cups[i]
             // if (new Date(data.end_date) >= new Date()) continue
              this.tournaments.push({'id': data.championat_id, 'name': data.name, 'logo': data.logo, 'sponsor': data.logo_sponsor})
            }
          })
          .catch((e) => {
            console.log(e)
            this.error="Ошибка при загрузке соревнований"
          })
    },
    loadMatches() {
      CligaService.getMatches(this.currentTournamentId)
          .then((response) => {
                this.matches = []
                for (let i=0; i<response.data.length; i++) {
                  let matches = response.data[i].matches
                  let dt = new Date()//Date(2021,3,10)
                  for (let j=0; j<matches.length; j++) {
                    let match = matches[j]
                    let dat = new Date(match.date)
                    if (this.allMatches || dat.getDate() == dt.getDate())
                      this.matches.push(match)
                  }
                }
          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    loadTable() {
      CligaService.getTable(this.currentTournamentId)
          .then((response) => {
            this.divisions = []
            this.divisions = response.data.divisions
          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    loadForwards() {
      if (!this.pageBestPlayer)
        return
      CligaService.getForwards(this.currentTournamentId)
          .then((response) => {
            this.forwards = []
            for (let i=0; i<response.data.length; i++) {
              this.forwards.push(response.data[i])
              if (i>=5) return
            }
          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    loadAssistants() {
      if (!this.pageBestPlayer)
        return
      CligaService.getAssistants(this.currentTournamentId)
          .then((response) => {
            this.assistants = []
            for (let i=0; i<response.data.length; i++) {
              this.assistants.push(response.data[i])
              if (i>=5) return
            }
          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    loadGoalPlusPass() {
      if (!this.pageBestPlayer)
        return
      CligaService.getGoalPlusPass(this.currentTournamentId)
          .then((response) => {
            this.goalpluspass = []
            for (let i=0; i<response.data.length; i++) {
              this.goalpluspass.push(response.data[i])
              if (i>=5) return
            }
          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    loadDefenders() {
      if (!this.pageBestPlayer)
        return
      CligaService.getDefenders(this.currentTournamentId)
          .then((response) => {
            this.defenders = []
            for (let i=0; i<response.data.length; i++) {
              this.defenders.push(response.data[i])
              if (i>=5) return
            }
          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    loadGoalkipers() {
      if (!this.pageBestPlayer)
        return
      CligaService.getGoalkipers(this.currentTournamentId)
          .then((response) => {
            this.goalkipers = []
            for (let i=0; i<response.data.length; i++) {
              this.goalkipers.push(response.data[i])
              if (i>=5) {
               // console.log(this.goalkipers)
                return
              }
            }

          })
          .catch((e) => {
            console.log(e)
            //this.error="Ошибка при загрузке матча"
          })
    },
    accept() {

      this.title = this.tournament
      this.currentTournamentId = this.selectedTournament.id
      this.visibleTitle = this.showTournament
      this.allMatches = this.showAllMatches
      this.pageBestPlayer = this.showBestPlayer
      this.pageCards = this.showCards
      this.loadMatches()
      this.loadTable()
      this.loadForwards()
      this.loadAssistants()
      this.loadGoalPlusPass()
      this.loadDefenders()
      this.loadGoalkipers()
      this.onResize()
      this.resetTimer()
      this.startTimer()
      this.resetTimerUpdate()
      this.startUpdate()
      this.dialog = false
    },
    onResize() {
      this.heightCar = this.$refs.resizableDiv.getBoundingClientRect().height - 70
      this.height = this.heightCar - 70
      if (!this.showTournament) {
        this.heightCar = this.heightCar + 70
        this.height = this.height + 70
      }
    },
    format(hour, minute) {
      let h = hour
      let m = minute
      if (hour   < 10) {h   = "0"+hour;}
      if (minute < 10) {m = "0"+minute;}
      return h+':'+m
    },
    stage(stage, tour) {
      switch (stage) {
        case 0: {
          if (tour > 0) return tour + ' тур'
          return 'гр.этап'
        }
        case 1: return 'финал'
        case 2: return '3 место'
        case 3: return 'финал див.'
        case 4: return '1/2 финала'
        case 5: return '5 место'
        case 6: return '1/4 финала'
        case 7: return '7 место'
        case 8: return '1/8 финала'
        case 9: return '1/16 финала'
        case 10: return 'сер.финал'
        case 11: return '3 м.сер.финала'
        case 12: return '1/2 сер.финала'
        case 13: return '1/4 сер.финала'
      }
      return ''
    },
    startTimer: function() {
      this.timer = setInterval(() => this.countdown(), this.carouselTimeout*1000);
    },
    startUpdate: function() {
      this.timerUpdate = setInterval(() => this.update(), this.updateTimeout*1000);
    },
    resetTimer: function() {
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTimerUpdate: function() {
      clearInterval(this.timerUpdate);
      this.timerUpdate = null;
    },
    countdown: function() {
      this.model++
    },
    update: function() {
      //console.log('UPDATE')
      this.loadTable()
      this.loadMatches()
      this.loadForwards()
      this.loadAssistants()
      this.loadGoalPlusPass()
      this.loadDefenders()
      this.loadGoalkipers()
    },
    onScroll (e) {
      console.log(e)
    }
  },
  watch: {
    selectedTournament: function (val) {
      if (val !== null) {
        this.tournament = this.selectedTournament.name
        this.pathToLogo = this.selectedTournament.logo
        this.pathToSponsor = this.selectedTournament.sponsor
        //console.log(this.selectedTournament.logo)
      }
    },
    selectedSport: function (val) {
      if (val !== null) {
        this.loadTournament()
      }
    }
  },
  components: {PlayerCard}
}
</script>

<style scoped>
h1 {font-family: 'Geometria'; font-size: 30pt; color: white;position: relative;}
h2 {font-family: 'Geometria'; font-size: 18pt; top: 15px; position: center; color: rgb(36,64,130);text-align: center;text-decoration:underline; }
h3 {font-family: 'Geometria'; font-size: 14pt; left: 15px; position: relative; color: rgb(36,64,130); }

.box {
  border: 3px rgb(36,64,130) solid;
  border-radius: 5px;
  background: rgba(255,255,255,0.8);
  height: 100%;
}
#tourntable {

}
#tournmatches {

}
#logo {
  height: 50px;
  float: left;
  margin-right: 10px;
}
#sponsor {
  height: 50px;
  position: absolute;
  right: 40px;
}
#title {
  margin-top: 10px;
  white-space:nowrap;
}
thead > tr {
  border-bottom: 2px rgb(36,64,130) solid;
  background: rgba(36,64,130, 0.8) !important;
}
thead > tr > th {
  border-bottom: 2px rgb(36,64,130) solid;
  text-align: center !important;
  padding: 0 10px !important;
  background: rgba(36,64,130, 0.8) !important;
}
tbody > tr {
  background: rgba(255,255,255, 0.8) !important;
}
tbody > tr > td {
  padding: 0 5px !important;
  border-top: 1px rgba(36,64,130, 0.5) dotted;

 /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}
body.darkScrollbar::placeholder {
  color: #b2aba1;
}
.box .v-data-table__wrapper::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  background-color: #143861;
}
.playercard {padding: 0px !important;margin: 8px !important;}
</style>
