import axios from 'axios'
import Settings from '@/config/env'

class CligaService {

    getTournaments(id) {
        return axios.get(Settings.API_URL + '/tournaments/' + id + '/' + (new Date()).getFullYear(), { })
    }

    getMatches(id) {
        return axios.get(Settings.API_URL + '/championats/' + id + '/calendar', { })
    }

    getTable(id) {
        return axios.get(Settings.API_URL + '/championats/table/' + id, { })
    }

    getForwards(id) {
        return axios.get(Settings.API_URL + '/championats/' + id + '/forwards', { })
    }

    getAssistants(id) {
        return axios.get(Settings.API_URL + '/championats/' + id + '/assistants', { })
    }

    getGoalPlusPass(id) {
        return axios.get(Settings.API_URL + '/championats/' + id + '/goalpluspass', { })
    }

    getDefenders(id) {
        return axios.get(Settings.API_URL + '/championats/' + id + '/defenders', { })
    }

    getGoalkipers(id) {
        return axios.get(Settings.API_URL + '/championats/' + id + '/goalkippers', { })
    }
}

export default new CligaService()