<template>
  <div class="content-blocker">

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
  },
  methods: {

  }
}
</script>

<style>
.content-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent url(/images/background-cliga.jpg) repeat top left;
  background-size:cover;
  z-index: 0;
  overflow: hidden;
}
html {overflow: hidden !important;}
</style>
