<template>
  <v-container>
    404
  </v-container>
</template>

<script>
export default {
name: "NotFound"
}
</script>

<style scoped>

</style>