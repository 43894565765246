
const URL = 'https://api.cliga.ru'

module.exports = {
    NODE_ENV: '"production"',
    SITE_NAME: 'Live',
    SITE_URL: URL,
    API_URL: URL + '/v1',
    CLIENT_URL: 'https://cliga.live.demo.corelab.team',
    IMAGE_URL: 'https://cliga.ru/',//URL,
}
