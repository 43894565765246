<template>
  <v-container fluid style="padding: 5px !important;" v-if="list && list.length > 0">
    <h3 class="players">{{ title }}</h3>
    <v-avatar width="100%" height="100hw" style="padding: 10px !important;" v-if="list && list.length > 0">
      <v-img
          position="top center"
          :src="src"
          alt="Лучший игрок"
          aspect-ratio="1"
          eager
      >
      </v-img>
    </v-avatar>
    <v-card
        class="mx-auto"
        min-width="100"
        style="background: rgba(36,64,130, 0.9) !important;"
    >

      <v-card-title v-if="list && list.length > 0"
      class="header_player">
        <div v-if="desc >= 4"
            style="position: relative; width: 100%;"
        >{{ family(list[0].name) }}
        </div>
        <div v-else
             style="position: relative; width: 100%;"
        >{{ family(list[0].real_name) }}
        </div>
        <div v-if="desc >= 4"
            style="position: relative; width: 100%;"
        >{{ name(list[0].name) }}
        </div>
        <div v-else
             style="position: relative; width: 100%;"
        >{{ name(list[0].real_name) }}
        </div>
        <div
            v-if="desc >= 4"
            style="position: relative; width: 100%; font-weight: 300; font-size: 10pt; margin: 0px;"
        >({{ list[0].team }})
        </div>
        <div
            v-else
            style="position: relative; width: 100%; font-weight: 300; font-size: 10pt; margin: 0px;"
        >({{ list[0].name }})
        </div>
        <div
            style="position: relative; width: 100%; font-weight: 300; font-size: 12pt; padding-left:0px;padding-right:0px; padding-top:20px;"
        >Игры: {{ list[0].matches }},
          <span v-if="desc==1">голы - <strong>{{ list[0].goals }}</strong></span>
          <span v-else-if="desc==2">пасы - <strong>{{ list[0].pass }}</strong></span>
          <span v-else-if="desc==3">гол+пасс - <strong>{{ list[0].gp }}</strong></span>
          <span v-else-if="desc==4">баллы - <strong>{{ list[0].points }}</strong></span>
          <span v-else-if="desc==5">сух.матчи - <strong>{{ list[0].dry }}</strong></span>
          <span v-else></span>
        </div>
      </v-card-title>
    </v-card>
    <v-container fluid class="fill-height" style="width: 100%; padding-left:0px;padding-right:0px; padding-top:10px;">
      <table style="width: 100%; margin: 0px; padding: 0px; border-spacing: 0px;">
        <tbody>
        <tr v-for="(player, index) in list.slice(1,5)" :key="index">
          <td style="text-align: center; width: 5%;">{{  index + 2 }}</td>
          <td v-if="desc >= 4" style="text-align: left">{{  fio(player.name) }}</td>
          <td v-else style="text-align: left">{{  fio(player.real_name) }}</td>
          <td v-if="desc == 1" style="width: 10%; text-align: center; font-weight: bold;">{{  player.goals }}</td>
          <td v-else-if="desc == 2" style="width: 10%; text-align: center; font-weight: bold;">{{  player.pass }}</td>
          <td v-else-if="desc == 3" style="width: 10%; text-align: center; font-weight: bold;">{{  player.gp }}</td>
          <td v-else-if="desc == 4" style="width: 10%; text-align: center; font-weight: bold;">{{  player.points }}</td>
          <td v-else-if="desc == 5" style="width: 10%; text-align: center; font-weight: bold;">{{  player.dry }}</td>
          <td v-else style="width: 10%; text-align: center"></td>
        </tr>
        </tbody>
      </table>
    </v-container>
  </v-container>
</template>

<script>

import Settings from '@/config/env'

export default {
name: "PlayerCard",
  props: {
    title: {
      type: String,
      default: null,
    },
    desc: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
    },
  },
  data: () => ({
    src: null,
  }),
  methods: {
    family(str) {
      let arr = str.split(' ')
      if (arr.length > 0) return arr[0]
      return str
    },
    name(str) {
      let arr = str.split(' ')
      if (arr.length > 1) return arr[1]
      return str
    },
    fio(str) {
      let arr = str.split(' ')
      if (arr.length == 2) return arr[0] + ' ' + arr[1][0] + '.'
      if (arr.length > 2) return arr[0] + ' ' + arr[1][0] + '.' + arr[2][0] + '.'
      return str
    }
  },
  watch: {
    list: function (val) {
      if (val !== null) {
        if (val.length > 0) {

          let photo = ''
          if (this.desc >= 4) photo = val[0].photo
          else photo = val[0].photo_url

          if (photo === '') this.src = '/images/no_photo.png'
          else this.src = Settings.IMAGE_URL + photo
        }
      }
    },
  },
}
</script>

<style scoped>
h3.players {font-family: 'Geometria' !important; text-align: center; font-size: 14pt; font-weight: bold; color: rgb(36,64,130); text-transform: uppercase;}
.header_player {font-family: 'Geometria' !important; line-height: normal; padding: 0px; text-align: center; font-size: 14pt; color: white;white-space: break-spaces !important; word-break: break-word !important;}

tbody > tr {
  background: rgba(255,255,255, 0.8) !important;
}
tbody > tr > td {
  padding: 5px 5px !important;
  border-top: 1px rgba(36,64,130, 0.5) dotted;
  color: rgb(36,64,130);
  font-family: 'Geometria' !important;
  font-size: 12pt;

}
strong {font-family: 'Geometria' !important; font-size: 14pt;}
</style>