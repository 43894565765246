import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from "@/views/Welcome";
import NotFound from "@/components/NotFound";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Главная страница',
    component: Welcome
  },
  {
    path: '/404',
    name: 'Страница не найдена',
    component: NotFound,
  }, {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
